<template>
  <div style="max-width: 100vw;">
    <div class="container-one">
      <div class="main-photo-1"></div>
      <div id="container">
        <div class="title text-left" style="margin-left:34px; font-size: 28px;">
          Have you ever come across situations like these:
        </div>
        <div class="row">
          <div class="col-lg-3 six-cards">
            <div class="row row-img ">
              <img id="img-one" src="@/assets/Group 448.png" alt="" />
            </div>
            <div class="row">
              <p>
                Where you spent hours over various summaries, and data, and you
                felt stuck, like your mind

                <strong>
                  was completely blank? <br /><br />
                  You waited for help from an auditor
                </strong>
                or a colleague?
              </p>
            </div>
          </div>
          <div class="col-lg-3 six-cards">
            <div class="row">
              <img id="img-one" src="@/assets/Group 450.png" alt="" />
            </div>
            <div class="row">
              <p>
                You did not know <strong> how to start </strong>and from where
                to collect the necessary information to arrive at the intended
                effect?
              </p>
            </div>
          </div>
          <div class="col-lg-3 six-cards">
            <div class="row">
              <img id="img-one" src="@/assets/Group 451.png" alt="" />
            </div>
            <div class="row">
              <p>
                <strong>
                  You can't add up the details, because the amount at the end of
                  the cash flow statement is not right
                </strong>
                or your conscience tells you that "something is wrong"?
              </p>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-3 six-cards">
            <div class="row">
              <img id="img-one" src="@/assets/Group 452.png" alt="" />
            </div>
            <div class="row">
              <p>
                You attended <strong> the best training</strong> in the city to
                hopefully learn about it, but you feel like you
                <strong
                  >have lost a few hundred dollars, and learned nothing in the
                  process?</strong
                >
              </p>
            </div>
          </div>
          <div class="col-lg-3 six-cards">
            <div class="row">
              <img id="img-one" src="@/assets/Group 453.png" alt="" />
            </div>
            <div class="row">
              <p>
                <strong> Do you postpone all other important tasks</strong>
                to make a cash flow statement, and then it turns out that
                <strong> the auditor is not satisfied with it?</strong>
              </p>
            </div>
          </div>
          <div class="col-lg-3 six-cards">
            <div class="row">
              <img id="img-one" src="@/assets/Group 454.png" alt="" />
            </div>
            <div class="row">
              <p>
                Are you stressed at work because
                <strong>
                  you do not know when, how, or what to do, to "move things
                  forward"</strong
                >
                and complete the last missing summary in the report?
              </p>
            </div>
          </div>
        </div>
        <div class="text-left description-1">
          If any of these things sound familiar to you and you've been
          wondering, "What did I do wrong?" You feel as though you are wasting
          your time, money, and efforts. You stress at work and you do not see
          the results.
        </div>
      </div>
    </div>
    <div class="container-two">
      <div class="row">
        <div class="col-lg-6 ">
          <div class="position-relative">
            <div class="square-right-bottom"></div>
            <img id="img-two" src="@/assets/Image -3.png" alt="" />
          </div>
        </div>
        <div class="col-lg-6">
          <div class="container-two-right">
            <h3 class="title-2 ">
              <strong class="font-italic">
                Then you need to take action.</strong
              >
            </h3>
            <p class="description-2">
              Systematically calculate everything and finish reporting with a
              clear conscience that you did the best you could and in accordance
              with the accounting rules.
              <br /><br />
              A few years ago, while working as an intermediate assistant
              <span class="strike-20"> for </span
              ><span class="strike-20">an </span
              ><span class="strike-20"> audit </span
              ><span class="strike-20">company,</span
              ><span class="strike-20"> I </span>
              <span class="strike-20 ">was </span>
              <span class="strike-20"> tasked </span>
              <span class="strike-20"> with </span>
              <span class="strike-20"> verifying</span>
              <span class="strike-20"> the cash </span>
              <span class="strike-20"> flow </span>
              <span class="strike-20 last-char"> statement. </span>
              I spent all night going through various materials and procedures.
              Phew.. somehow it worked! I thought to myself, is it really that
              difficult? <br />
              <span class="strike-20">Is </span>
              <span class="strike-20">there</span>
              <span class="strike-20">no</span>
              <span class="strike-20"> easier </span>
              <span class="strike-20">way to</span>
              <span class="strike-20 last-char">do this?</span>
              I decided, that this was not how it should be done. By verifying
              the cash flow statements several times a year, I was wasting hours
              of invaluable time on them. And then one day I developed my own,
              proprietary method of their verification, and it still works for
              me to this day! It has paid off by saving me time, and earned me
              the recognition amongst subordinates and superiors. I invite you
              to take advantage of my experience...
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="container-three">
      <h1 class="title-3">My method is simple and effective</h1>
      <div id="container" class="row">
        <div class="col-lg-3 block-4">
          <div class="classWithPad">
            <img id="img-three" src="@/assets/methods (1).png" alt="" />
            <p>
              <strong>No more complexities </strong><br />
              and scientific translations.
            </p>
          </div>
        </div>
        <div class="col-lg-3 block-4">
          <div class="classWithPad">
            <img id="img-three" src="@/assets/methods (2).png" alt="" />
            <p>
              You will organize your work
              <strong>at any time and at your </strong>
              convenience.
            </p>
          </div>
        </div>
        <div class="col-lg-3 block-4">
          <div class="classWithPad">
            <img id="img-three" src="@/assets/methods (3).png" alt="" />
            <p>
              <strong> No need to spend additional costs</strong> on courses,
              travel, hotels, etc. They are additional costs that you can avoid!
            </p>
          </div>
        </div>
        <div class="col-lg-3 block-4">
          <div class="classWithPad">
            <img id="img-three" src="@/assets/methods (4).png" alt="" />
            <p><strong>My method is simple and effective</strong></p>
          </div>
        </div>
      </div>
      <h4>
        <strong
          >Thanks to the effective method of Preparing the Cash Flow Statement,
          YOU WILL GAIN:</strong
        >
      </h4>
      <div id="container" class="row ">
        <div class="col-lg-3  block-4">
          <div class="classWithPad">
            <div class="yellow-background">
              <img id="img-three" src="@/assets/methods (5).png" alt="" />
            </div>
            <h5>A lot of time and less stress at work</h5>
          </div>
        </div>
        <div class="col-lg-3  block-4">
          <div class="classWithPad">
            <div class="yellow-background">
              <img id="img-three" src="@/assets/methods (6).png" alt="" />
            </div>
            <h5>Recognition among your superiors</h5>
          </div>
        </div>
        <div class="col-lg-3  block-4">
          <div class="classWithPad">
            <div class="yellow-background">
              <img id="img-three" src="@/assets/methods (7).png" alt="" />
            </div>
            <h5>Recognition among subordinates</h5>
          </div>
        </div>
        <div class="col-lg-3  block-4">
          <div class="classWithPad">
            <div class="yellow-background">
              <img id="img-three" src="@/assets/methods (8).png" alt="" />
            </div>
            <h5>More time for family, friends, and to persue your hobbies</h5>
          </div>
        </div>
      </div>
    </div>

    <div class="container-four row">
      <div class="col-lg-6 text">
        <h3>
          While teaching myself how to prepare the cash flow statment, I was
          looking for a similar product.
        </h3>
        <p>
          If all this sounds familiar to you and you have been looking for a
          solution, now you can have it! Work more calmly, reduce labor
          consumption and improve your work. And now for the best part… 
          <span class="strike-20"> you can </span>
          <span class="strike-20"> get it </span>
          <span class="strike-20"> all with</span>
          <span class="strike-20"> minimal</span>
          <span class="strike-20"> effort, </span>
          <span class="strike-20"> the effects </span>
          <span class="strike-20"> of which </span>
          <span class="strike-20"> will pay </span>
          <span class="strike-20"> off in the </span>
          <span class="strike-20"> long run. </span>
          It is possible because the method I suggest works immediately and you
          can have better results in the coming years. It is based on an
          appropriately developed template that guides you step by step of the
          way.
          <br /><br />
          <strong>
            My experience shows that 90% of the effort which goes towards the
            preparation of the cash flow statement are standard, and only 10%
            relates to non standard issues.</strong
          >
        </p>
      </div>
      <div class="col-lg-6">
        <div class="position-relative">
          <div
            style=" transform: translateY(-120px);z-index: 1000;"
            class="square-left-bottom "
          ></div>
          <img class="transform" src="../assets/asset-1.png" alt="" />
        </div>
      </div>
    </div>
    <div class="container-four row">
      <div class="col-lg-6">
        <div class="position-relative">
          <div class="square-right-bottom "></div>
          <img src="../assets/asset-2.png" alt="" />
        </div>
      </div>
      <div class="col-lg-6 text">
        <h3>
          If you could save 90% of your time, how much more will you gain?
        </h3>
        <p>
          Non standard issues faced with systematically can save you minutes,
          and sometimes even hours. However, lets set realistic and responsible
          expectations! Use our method and have long-term results. This is no
          miracle product, the type of which you do once and the effect lasts
          forever. It is a product for people who would want to improve their
          skills permanently. They also understand that improvement is possible
          only when they work towards it everyday.

          <span class="strike-20"> It </span>
          <span class="strike-20"> is </span>
          <span class="strike-20"> a product </span>
          <span class="strike-20"> for </span>
          <span class="strike-20"> people, </span>
          <span class="strike-20"> who would</span>
          <span class="strike-20"> want to </span>
          <span class="strike-20"> improve </span>
          <span class="strike-20"> their </span>
          <span class="strike-20"> skills </span>
          <span class="strike-20"> permanently. </span>
          <span class="strike-20"> They also </span>
          <span class="strike-20"> understand</span>
          <span class="strike-20"> that </span>
          <span class="strike-20"> improvement </span>
          <span class="strike-20"> is possible </span>
          <span class="strike-20"> only </span>
          <span class="strike-20"> when they</span>
          <span class="strike-20">work towards</span>
          <span class="strike-20"> it everyday.</span>
          This product will not fix your account clutter and we will not be held
          responsible for incorrect financial statements. But if you enter the
          correct data, making a cash flow statement will always be easy.
        </p>
      </div>
    </div>
    <div class="container-five mt-4">
      <div class="first">
        <h2>
          Now, when the price is only $97 you can take advantage of our offer
          to:
        </h2>
        <ul>
          <li class="row">
            <img class="col-3" src="../assets/icon (1).png" alt="" /><strong
              class="col-7"
              >Have more time for yourself</strong
            >
          </li>
          <li class="row">
            <img class="col-3" src="../assets/icon (2).png" alt="" /><strong
              class="col-7"
              >Stress less at work
            </strong>
          </li>
          <li class="row">
            <img class="col-3" src="../assets/icon (3).png" alt="" /><strong
              class="col-7"
              >Gain recognition from superiors and subordinates</strong
            >
          </li>
          <li class="row">
            <img class="col-3" src="../assets/icon (4).png" alt="" /><strong
              class="col-7"
              >Spend more time with family, friends and hobbies</strong
            >
          </li>
        </ul>
      </div>
      <div class="second">
        <div>
          <h4>Pay only</h4>
          <h1>97 USD</h1>
          <h2>net</h2>
          <small
            >to have access for upto 3-months to the application on the website
          </small>
          <br />
          <button class="btn btn-white">
            <router-link to="/sheet">
              <span class="btn-white-inner">order</span></router-link
            >
          </button>
        </div>
      </div>
      <img class="third" src="../assets/iPhone 11 - Pro.png" alt="" />
    </div>
    <div class="container rel-carousel">
      <h1 class="title text-center">
        Why an effective method of preparation cash flow statement is a product
        worth trying?
      </h1>
      <br />
      <div class="text-center"><p>See what accountants say...</p></div>
      <br /><br />
      <div
        id="carouselExampleIndicators"
        class="carousel slide"
        data-ride="carousel"
      >
        <ol class="carousel-indicators" style="z-index:9999;">
          <li
            data-target="#carouselExampleIndicators"
            data-slide-to="0"
            class="active"
          ></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
        </ol>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <div class="container carousel-container">
              <div class="row">
                <div class="col-lg-3">
                  <br /><br />
                  <img id="img-four" src="../assets/Image -5.png" />
                  <br />
                  <br />
                  <h5>Dorothy,</h5>
                  <p>Chief accountant</p>
                </div>
                <div class="col-lg-9">
                  <h5>I found the solution!</h5>
                  <p>
                    I have had problems with cash flows ever since I can
                    remember. Even as an experienced accountant, I spent 2-3
                    days per year, each year preparing a cash flow statement.
                    During the development of the company, the problem was
                    mounting and I had to take some action! I had tested all
                    available advertised trainings in my city, but everywhere
                    the same result- a lot of theories that I already knew.
                    Another issue with the methods taught in the trainings, was
                    that they were always led to problems such as; agreeing on
                    the results so that the CEO and auditors were satisfied. It
                    happened that sometimes the next day I had problems waking
                    up, as I had spent the entire night trying to agree with the
                    cash flow statement.
                  </p>
                  <p>
                    Today I finally solved this problem. While aimlessly
                    browsing through the internet, I found an application on
                    www.cashflowstatement.online by accident! I tried it because
                    I was desperate to find an easier way to do this. I did not
                    expect any spectacular results, but what turned out
                    exceeded my expectations completely.
                  </p>
                  <p>
                    After using the application myself for the first time, I was
                    able to prepare the cash flow statement, and then every
                    month after that, without any issues. And so to this day, I
                    have been using the method for about a year now and I don't
                    see a better solution to my problems. I don't have to suffer
                    the pain anymore and work late nights!
                  </p>
                  <p>
                    My mood improved significantly during the month-end close
                    preparing reports for the Management Board.
                  </p>
                  <p>
                    Now I recommend
                    <a class="strike-20 link" href="/"
                      >www.cashflowstatement.online</a
                    >
                    application to all my friends who also faced similar issues
                    preparing cash flow statements.
                  </p>
                  <div class="text-right"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <div class="container carousel-container">
              <div class="row">
                <div class="col-lg-3">
                  <br /><br />
                  <img id="img-four" src="../assets/comments-2.jpg" />
                  <br />
                  <br />
                  <h6>Thomas,</h6>
                  <p>Chief Financial Officer</p>
                </div>
                <div class="col-lg-9">
                  <h5>Much more efficiently ...</h5>
                  <p>
                    I recommend this app to all accountants.
                  </p>
                  <p>
                    I spent too much time compiling the cash flow statement,
                    i.e. to <span class="strike-20">reconcile</span> all changes
                    on the balance sheet items with the Excel file used at work.
                  </p>
                  <p>
                    Using the application on
                    <a class="strike-20 link" href="/"
                      >www.cashflowstatement.online,</a
                    >
                    the work goes much more efficiently, because I can
                    systematically create cash flow statements, thanks to which
                    I save a lot of time. In addition, I get a clear list of all
                    the items, which makes it easier to answer the questions of
                    the Management Board.
                  </p>

                  <div class="text-right"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <div class="container carousel-container">
              <div class="row">
                <div class="col-lg-3">
                  <br /><br />
                  <img id="img-four" src="../assets/comments-3.jpg" />
                  <br />
                  <br />
                  <h6>Madeline,</h6>
                  <p>Chief accountant</p>
                </div>
                <div class="col-lg-9">
                  <h5>Problem solved!</h5>
                  <p>
                    A very useful application.
                  </p>
                  <p>
                    I did the cash flows sporadically, so I had to remember the
                    basics each time, but now thanks to this application I save
                    a lot of time and questions from my colleagues. So far, I
                    have missed such a tool that makes my work easier on the
                    market.
                  </p>

                  <div class="text-right"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <router-link to="/sheet" v-if="!smallS">
          <button class="mt-4 btn btn-black carousel-button">
            <span style="font-weight:bold; color:yellow;">BUY</span>
          </button>
        </router-link>
      </div>
      <router-link to="/sheet" v-if="smallS">
        <button class="mt-4 btn btn-black carousel-button">
          <span style="font-weight:bold; color:yellow;">BUY</span>
        </button>
      </router-link>
    </div>

    <div class="container-six yellow-background">
      <h1>
        Which application features do you think will be the most interesting for
        you?
      </h1>
      <div id="container" class="row">
        <div class="col-lg-3 block-4">
          <div class="classWithPad">
            <img id="img-three" src="../assets/methods (1).png" alt="" />
            <p>
              <strong>Simple, transparent and automated application.</strong>
            </p>
          </div>
        </div>
        <div class="col-lg-3 block-4">
          <div class="classWithPad">
            <img id="img-three" src="../assets/methods (2).png" alt="" />
            <p>
              <strong>A positive impact on your well-being </strong> during the
              most stressful time of the reporting period.
            </p>
          </div>
        </div>
        <div class="col-lg-3 block-4">
          <div class="classWithPad">
            <img id="img-three" src="../assets/methods (3).png" alt="" />
            <p>
              <strong> Time-saving </strong>at work and the ability to focus on
              other, often more urgent issues.
            </p>
          </div>
        </div>
        <div class="col-lg-3 block-4">
          <div class="classWithPad">
            <img id="img-three" src="../assets/methods (4).png" alt="" />
            <p>
             Less overtime and more time for family, friends, and <span class="strike-20">persuing your hobbies.</span> 

            </p>
          </div>
        </div>
      </div>
      <h5 class="mt-3">
        Are you sitting in front of the computer, reading this text, and want to learn how to properly prepare a cash flow statement?
      </h5>
      <router-link to="/sheet">
        <button class="mt-4 btn btn-black ">
          <span class="btn-black-inner" style="font-weight:bold;"
            >BUY</span
          >
        </button>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "About",
  data: function() {
    return {
      smallS: false,
    };
  },
  created() {
    if (window.innerWidth <= 992) this.smallS = true;
    else this.smallS = false;
    window.addEventListener("resize", this.handleResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      if (window.innerWidth <= 992) this.smallS = true;
      else this.smallS = false;
    },
  },
};
</script>
<style scoped>
.classWithPad {
  margin: 10px;
  box-shadow: 0px 7px 20px 7px rgba(0, 0, 0, 0.15);
  height: 300px;
  background-color: white;
}
.six-cards {
  background-color: white;
  margin: 34px;
  box-shadow: 0px 7px 20px 7px rgba(0, 0, 0, 0.15);
}
.carousel-indicators li {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: black;
}
.carousel-indicators {
  left: -42%;
  margin: 0;
  z-index: 0;
}

/* .carousel-button {
position: absolute;
left: 27%;
top: 92%;
cursor: pointer;
} */
.carousel-button {
  position: absolute;
  left: 70%;
  bottom: 1%;
}
.carousel-item {
  padding-bottom: 100px;
}

.six-cards .row {
  text-align: left;
  padding: 22px;
  font-size: 20px;
}
.six-cards strong {
  font-style: italic;
}
.row {
  margin: 0;
}
/* containers */
.container-one {
  position: relative;
  margin-bottom: 100px;
}
.container-two {
  margin: 0px 19px 30px 19px;
}
.container-two-right {
  padding: 40px 0 40px 150px;
}
/* descriptions */
.description-1 {
  margin-top: 20px;
  margin-left: 34px;
  width: 50%;
  font-size: 20px;
  font-weight: bold;
}
.description-2 {
  text-align: left;
  padding: 0px 5px;
}
/* titles */
.title {
  font-weight: bold;
  font-style: italic;
  font-size: 2rem;
  padding: 30px 0 10px 0;
}
.link {
  text-decoration: none;
  color: black;
  font-weight: 600;
}
.title-2 {
  font-size: 2rem;
  padding: 10px 5px;
}
.title-3 {
  text-align: center;
}
.container-four .col-lg-6 {
  padding: 0;
}
.container-four .text {
  padding: 50px 50px 0 50px;
}
.container-four .text h3 {
  margin-bottom: 10px;
  font-size: 28px;
}
.container-four .text p {
  font-size: 22px;
}
.container-four img {
  z-index: -10;
  width: 100%;
}
.container-four .transform {
  transform: translateY(-120px);
}

/* images */
#img-two {
  width: 100%;
}
#img-one {
  margin: auto;
}
#img-three {
  height: 130px;
  margin: 20px;
}
#img-four {
  width: 100%;
  object-fit: cover;
  transform: skew(-3deg);
}
.container-three {
  margin-left: 9px;
  margin-right: 9px;
}
.container-three .block-4 {
  text-align: center;
  z-index: 100;
}

.container-three h5 {
  margin-top: 30px;
  font-weight: bold;
  font-style: italic;
}

.container-three h4 {
  text-align: center;
  font-size: 20px;
  padding: 25px 0 55px 0;
  font-weight: bold;
  font-style: italic;
}
.yellow-background {
  background-color: #ffff00;
  margin: 0;
  padding: 0px 9px 0px 9px;
}
.container-five {
  background-color: #ffff00;
  z-index: 1;
  position: relative;
  height: 1100px;
  width: 100%;
}
.container-five .first {
  background-color: rgb(255, 255, 255);
  z-index: 1000;
  position: absolute;
  padding: 20px;
  width: 80%;
  transform: translateX(10%);
  top: 10px;
}
.container-five .second {
  background-color: black;
  color: white;
  position: absolute;
  width: 100%;
  top: 36%;
  height: 360px;
  left: 0;
  padding-top: 100px;
  text-align: center;
}

.container-five ul {
  list-style: none;
}
.container-five ul li {
  margin: 12px 0;
  font-style: italic;
}
.container-five ul img {
  object-fit: contain;
  max-width: 90px;
}
.container-five .first h2 {
  margin: 50px 0 20px 0;
}

.container-five .third {
  position: absolute;
  bottom: -8%;
  left: 50%;
  z-index: 10;
  transform: translateX(-50%);
  width: 300px;
}

.container-six {
  text-align: center;
}
.container-six h1 {
  max-width: 700px;
  margin: 10px auto;
  padding: 30px 0px;
}

@media (max-width: 557px) {
  .container-five {
    height: 1450px !important;
  }
  .container-five .second {
    top: 43% !important;
  }
  .container-five .third {
    bottom: 1% !important;
    left: 52%;
  }
}
@media only screen and (max-width: 768px) {
  .container-five .first {
    left: 2% !important;
  }
  .container-five .second {
    top: 40%;
  }
  .container-five .third {
    bottom: -5%;
    left: 52%;
  }
}
@media only screen and (max-width: 992px) {
  .carousel-indicators li {
    width: 10px;
    height: 15px;
    border-radius: 100%;
    background-color: black;
  }
  .carousel-button {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
  .carousel-indicators {
    left: 0%;
    top: 93%;
  }

  .description-1 {
    width: 85%;
  }
  .main-photo-1 {
    background-image: url("../assets/about-2.png");
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 500px;
    display: none;
  }
  .container-two-right {
    padding: 30px 0 30px 20px;
  }
  .container-four {
    display: none;
  }
  .square-right-bottom {
    width: 30%;
  }
  .container-five {
    display: flex;
    flex-direction: column;
    height: 1250px;
  }
  .container-five .first {
    left: 2%;
  }
  .container-five .second {
    top: 36%;
  }
  .container-five .third {
    bottom: -1%;
    left: 52%;
  }
}

@media only screen and (min-width: 993px) {
  .main-photo-1 {
    background-image: url("../assets/about-1.png");
    position: absolute;
    width: 250px;
    height: 100%;
    top: 0;
    right: 0px;
    background-size: cover;
  }
  .container-two-right {
    padding: 10px 0 10px 20px;
  }
  .container-four .text p {
    font-size: 14px;
  }
  .container-four .text h3 {
    font-size: 22px;
  }
  .container-five {
    height: 600px;
  }
  .container-five .first {
    top: 40px;
    left: 0;
    width: 35%;
  }
  .container-five .first h2 {
    font-size: 16px;
  }
  .container-five .first li {
    font-size: 13px;
  }
  .container-five .second {
    top: 15px;
    left: 33%;
    height: 80%;
    width: 44%;
    transform: skew(-9deg);
  }
  .container-five .second div {
    transform: skew(9deg);
  }
  .container-five .third {
    bottom: 5%;
    left: 83%;
    width: 360px;
  }
}
@media only screen and (min-width: 1182px) {
  .main-photo-1 {
    width: 475px;
  }
  .container-two-right {
    padding: 15px 0 15px 25px;
    font-size: 16px;
  }
  .container-four .text p {
    font-size: 17px;
  }
  .container-four .text h3 {
    font-size: 26px;
  }
}
@media only screen and (min-width: 1307px) {
  .main-photo-1 {
    width: 600px;
  }
  .container-two-right {
    padding: 15px 0 15px 40px;
    font-size: 18px;
  }
  .container-four .text p {
    font-size: 20px;
  }
  .container-four .text {
    padding: 80px 80px 0 80px;
  }
  .container-four .text h3 {
    font-size: 32px;
  }
  .container-five .third {
    bottom: 2%;
    left: 82%;
    width: 460px;
  }
}
@media only screen and (min-width: 1600px) {
  .container-two-right {
    padding: 5px 0 5px 40px;
    font-size: 22px;
  }
}
</style>
